<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-toolbar color="red" dark class="headline">{{ title }}</v-toolbar>

      <v-card-text>
        <div class="mt-6 body-1">{{ text }}</div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="emitAction(false)"
          >Nein</v-btn
        >
        <v-btn color="red darken-1" text @click="emitAction(true)">Ja</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AreYouSureDialog",
  props: ["title", "text", "dialog"],

  components: {},

  data() {
    return {};
  },

  methods: {
    emitAction(value) {
      this.$emit("dialogEmit", value);
    },
  },
};
</script>

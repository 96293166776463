<template>
  <v-chip class="mb-2" label small>
    <v-icon left>mdi-label</v-icon>
    {{ name }}
  </v-chip>
</template>

<script>
import store from "@/store";

export default {
  name: "CompanyInfoFooter",

  components: {},

  data() {
    return {
      name: store.getters["auth/session"].currentCompany.companyName,
    };
  },

  created: function () {
    /* VUEX store watcher. It fires when companyUuid changes */
    this.$store.watch(
      () => store.getters["auth/session"].currentCompany.companyName,
      (value) => {
        this.name = value;
      }
    );
  },
};
</script>
